<template>
    <div class="settle">
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>结算明细</span>
                </div>
            </template>
            <div>
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24"   style="text-align:left;">
                            <div class="searchblock">
                                <el-select v-model="merchantid" filterable style="margin-right:10px;width:150px" clearable placeholder="请选择商户" size="small" v-if="ismerchant != 1">
                                    <el-option v-for="item in merchants" :key="item" :value="item.ID" :label="item.Merchant"></el-option>
                                </el-select>
                                <el-select v-model="type" style="margin-right:10px;width:150px" clearable size="small">
                                    <el-option label="租金结算" value='1'></el-option>
                                    <el-option label="充值结算" value='2'></el-option>
                                    <el-option label="商户提现" value='3'></el-option>
                                </el-select>
                                <el-date-picker v-model="range" type="daterange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"  style="margin-right:10px;" size="small"></el-date-picker>
                                <el-input v-model="keyword"  placeholder="请输入流水号/订单号/手机号" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="primary"  style="margin-left:10px;" class="hidden-sm-and-down" size="small">
                                    <download-excel class="btn btn-default"                 :fetch="fetchData" :fields="json_fields"
                                        :before-generate="startDownload" :before-finish="finishDownload" worksheet="My Worksheet"
                                        type="csv" name="结算数据.csv">
                                        <span style="padding: 11px 20px; margin: 11px -20px">导出</span>
                                    </download-excel>
                                </el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div style="padding:20px 20px 0 0;width:30%;display:flex;justify-content:space-between;">
                    <span>订单总金额:{{total?total:0}}元</span> <span>结算总金额:{{settle?settle:0}}元</span>
                </div>
                <el-table :data="tabledata" border size="small" style="margin-top:20px;" v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中...">
                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                    <el-table-column label="商户名称" prop="Merchant" v-if="ismerchant != 1"></el-table-column>
                    <el-table-column label="交易流水" prop="BillNo"></el-table-column>
                    <el-table-column label="订单编号" prop="OrderUID">
                        <template #default='scope'>
                            <span v-if="scope.row.OrderUID">{{scope.row.OrderUID}}</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="结算时间" prop="AddDTime">
                        <template #default='scope'>
                            {{formatDate(scope.row.AddDTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" prop="Type">
                        <template #default='scope'>
                            <span v-if="scope.row.Type == 1">订单结算</span>
                            <span v-if="scope.row.Type == 2">充值结算</span>
                            <span v-if="scope.row.Type == 3" style="#color:#ff0000">提现</span>
                        </template>
                    </el-table-column>
                    
                    
                    
                    
                    <el-table-column label="交易金额(元)">
                        <template #default='scope'>
                            <span v-if="scope.row.OrderMoney">{{scope.row.OrderMoney/100}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="结算金额(元)" >
                        <template #default='scope'>
                            <span v-if="scope.row.Amount >= 0">{{scope.row.Amount/100}}</span>
                            <span v-if="scope.row.Amount < 0" style="color:#ff0000">¥{{scope.row.Amount/100}}</span>
                        </template>
                    </el-table-column>
                        
                    <el-table-column label="账户余额" prop="CurCredit">
                        <template #default='scope'>
                            {{scope.row.CurCredit/100}}
                        </template>
                    </el-table-column>

                    <el-table-column label="用户手机号" prop="Mobile">
                        
                    </el-table-column>
                    <el-table-column label="备注" prop="Remark"></el-table-column>
                    <el-table-column label="操作" v-if="ismerchant == 0">
                        <template #default="scope">
                            <el-button size="mini" @click="del(scope.row.ID)" type="primary">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    
</template>
<script>
import Cookies from 'js-cookie'
import constant from '@/constant'
import JsonExcel from "vue-json-excel3";
import { ElMessage } from "element-plus";

export default {
    components: {
		downloadExcel: JsonExcel,
	},
    data(){
        return {
            tradetype:'',
            tabledata:[],
            ismerchant:'',
            mid:'',
            addsettle:false,
            keyword:'',
            range:'',
            merchantid:'',
            curpage:1,
            pagesize:50,
            totalcount:'',
            type:'',
            settleinfo:{
                merchantid:'',
                amount:'',
                orderuid:'',
                remark:'',
            },
            total:0,
            settle:0,
            load:false,
            json_fields: {
				商户名称: "Merchant",
				交易流水号: "BillNo",
				订单编号: "OrderUID",
				结算时间: "AddDTime",
                类型: {
					field: "Type",
					callback: (value) => {
						if (value == 1) {
							return `订单结算`;
						}
						if (value == 2) {
							return `充值结算`;
						}
						if (value == 3) {
							return `提现`;
						}
					},
				},
				交易金额: "Amount",
				结算金额: "Amount",
				账户余额: "CurCredit",
                用户手机号:"Mobile"
			},
        }
    },
    methods:{
        async fetchData() {
			const response = await this.axios.get(constant.exportsettle, {
				headers: {
					"Content-Type": "application/json",
				},
				params: {
					keyword:this.keyword,
                    merchantid:this.merchantid,
                    type:this.type,
                    range:JSON.stringify(this.range),
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    tradetype:this.tradetype
				},
			});
			console.log(response.data);
			if (response.data.length == 0) {
				ElMessage({
					type: "warning",
					message: "暂无数据可下载",
					center: true,
				});
				return false;
			}
			return response.data;
		},
		startDownload() {
			ElMessage({
				type: "warning",
				message: "开始下载",
				center: true,
			});
		},
		finishDownload() {
			ElMessage({
				type: "success",
				message: "下载完成",
				center: true,
			});
		},
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        del(id){
            console.log(id)
            this.axios.get(constant.delorder,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }     
            }).then((data)=>{
                if(data.data.code == 200){
                    this.$message.success("删除成功");
                }else{
                    this.$message.error("删除失败");
                }
                console.log(data)
            })
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        
        Cancel(){
            this.addsettle = false;
        },
        add(){
            this.addsettle = true;
        },
        init(){
            this.load = true;
            this.axios.get(constant.getsettle,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    keyword:this.keyword,
                    merchantid:this.merchantid,
                    type:this.type,
                    range:JSON.stringify(this.range),
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    tradetype:this.tradetype
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.totalcount = response.data.totalcount*1;
                this.curpage = response.data.curpage*1;
                this.total = response.data.total.OrderMoney*1/100;
                this.settle = response.data.total.Amount*1/100;
                this.load = false;
            });
        },
        formatDate(date){
            var datetime = new Date(date*1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2); 
            var second = ("0" + datetime.getSeconds()).slice(-2); 
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>
<style scoped>
.tag-form-item {
    margin-bottom: 0;
}
.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>